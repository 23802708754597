.site-hero {
  min-height: 40vh;
}

.from-now {
  white-space: nowrap;
}

ul {
  list-style-type: none;
}

.bg-vko-1 {
  background-color: #999;
}